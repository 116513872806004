<style lang="css">
.vue-tags-input .ti-autocomplete {

}
.ti-item {
  color: black;
}
</style>

<template>
  <div class="row">
    <div class="box">
      <div class="box-header with-border">
        <h4 class="box-title">
          Usuario <strong>{{ fullname }}</strong>
        </h4>
      </div>
      <div class="box-body row">
        <div class="col-3">
          <div class="box">
            <div class="flexbox align-items-center px-20 pt-20">
              <label class="toggler toggler-danger font-size-16">
                <input type="checkbox" checked="">
                <i class="fa fa-heart"></i>
              </label>
              <div class="dropdown">
                <a
                  data-toggle="dropdown" href="#" aria-expanded="false"
                ><i class="ti-more-alt rotate-90 text-muted"></i></a>
                <div
                  class="dropdown-menu dropdown-menu-right"
                  x-placement="bottom-end"
                  style="position: absolute; transform: translate3d(13px, 18px, 0px); top: 0px; left: 0px; will-change: transform;"
                >
                  <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
                  <a class="dropdown-item" href="#"><i class="fa fa-picture-o"></i> Shots</a>
                  <a class="dropdown-item" href="#"><i class="ti-check"></i> Follow</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#"><i class="fa fa-ban"></i> Block</a>
                </div>
              </div>
            </div>
            <div class="box-body text-center pt-1 pb-50">
              <a href="#">
                <img
                  class="avatar avatar-xxl"
                  :src="avatar ? avatar : '/images/profile.svg'"
                >
              </a>
              <h5 class="mt-3 mb-1">
                <a class="hover-primary" href="#">
                  {{ fullname }}
                </a>
              </h5>
              <p class="text-fade">
                {{ email }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-7">
          <vue-tags-input
            v-model="tag"
            :placeholder="null"
            :add-only-from-autocomplete="true"
            :autocomplete-filter-duplicates="true"
            :avoid-adding-duplicates="true"
            :tags="tags"
            :autocomplete-items="filteredItems"
            @before-adding-tag="onBeforeAddTag"
            @tags-changed="onTagsChanged"
          />
        </div>
      </div>
      <div class="box-footer">
        <button class="btn btn-primary float-right" @click="$emit('requestSave', current.privileges)">
          Guardar
        </button>
        <button class="btn btn-secondary" @click="$emit('requestCancel')">
          Cerrar
        </button>
      </div>
    </div>

    <div class="col-8"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VueTagsInput, createTags } from "@johmun/vue-tags-input";
import UsersApi from "@/services/Users.service";

export default {
  name: "UserEditBox",
  components: {
    VueTagsInput
  },
  props: {
    editedUserId: {
      type: String,
      required: true,
      default: ""
    },
    fullname: {
      type: String,
      required: true,
      default: "Juan Joy"
    },
    email: {
      type: String,
      required: false,
      default: "juan@perez.mx"
    },
    privileges: {
      type: Array,
      required: true,
      default: () => []
    },
    avatar: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      tag: "",
      tags: [],
      privilegeList: [],
      // Las `props` se usan de inicializador
      current: {
        privileges: this.privileges
      }
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT"]),
    filteredItems() {
      return this.privilegeList.filter(i => {
        const res = i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
        return res;
      });
    }
  },
  watch: {
    privileges(newVal, oldVal) {
      console.log("Priv changed: ", newVal, " | was: ", oldVal);
      this.tags = createTags(newVal);
    }
  },
  methods: {
    onBeforeAddTag(tag) {
      // console.log("Try to add tags: ", tag);
      tag.addTag(); // Si se reimplementa hay que llamar addTag() para que la agregue
    },
    onTagsChanged(newTags) {
      // No estan ordenadas, estan en el orden que se escriben
      // confiamos que el backend las ordenará
      this.current.privileges = newTags.map( t => t.text);
    },
  },

  mounted() {
    UsersApi.init(`${this.API_ROOT}/api/users`);
    UsersApi.getPrivileges()
      .then((res) => {
        const keys = Object.keys(res);
        const privs = keys.map((it) => {
          const { description } = res[it];
          return {
            text: it, // : ${description}`,
            description
          };
        });
        this.privilegeList = privs;
      });

    this.tags = createTags(this.privileges);
  }
};
</script>
