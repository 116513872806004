<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.profile-user-img {
  margin: 5px auto;
  width: 100px;
}
.profile-user-info {
  padding: 15px 15px;
  margin-top: 15px;
}
</style>
<template>
  <div class="box">
    <div class="box-body box-profile">
      <img
        class="profile-user-img rounded-circle img-fluid mx-auto d-block"
        :src="avatar"
        alt="User profile picture"
      >
      <h3 class="profile-username text-center">
        {{ name }}
      </h3>

      <p class="text-muted text-center">
        <span
          v-for="item in privileges"
          :key="item"
          class="badge badge-pill badge-default"
          :class="{ 'text-warning': item == 'ROCKET-ARBITER' }"
        >{{ item }}
        </span>
      </p>

      <!-- <a @click="onCheckout" href="#"> checkout </a> -->
      <!-- <div ref="card"></div> -->

      <div class="row">
        <div class="col-12">
          <div class="profile-user-info">
            <p>Email</p>
            <h6 class="margin-bottom">
              {{ email }}
            </h6>
            <!-- <p>Phone</p>
            <h6 class="margin-bottom">+11 123 456 7890</h6>-->
            <!-- <p>Address</p>
            <h6 class="margin-bottom">123, Lorem Ipsum, Florida, USA</h6>-->
          </div>
        </div>
      </div>
    </div>
    <!-- /.box-body -->
  </div>
</template>

<script>
// import { mapState } from "vuex";

export default {
  name: "ProfileBox",
  components: {
  },
  props: {
    name: String,
    email: String,
    avatar: {
      type: String,
      default() { return "http://www.gravatar.com/avatar/?d=identicon"; }
    },
    privileges: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {

    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    // ...mapState(["privileges", "user"])
  },
  mounted() {

  }
};
</script>
