<style scoped>
.avatar {
  background-color: unset;
}
</style>

<template>
  <div class="box">
    <div class="box-header with-border">
      <h5 class="box-title">
        Usuarios
      </h5>
    </div>
    <div class="box-body p-0">
      <vuetable
        ref="vuetableProfiles"
        class="table table-hover media-list media-list-hover media-list-divided"
        :api-url="`${API_ROOT}/api/users`"
        :fields="fieldsUsers"
        :http-options="{withCredentials: true}"
        track-by="_id"
        data-path="rows"
        pagination-path
        @vuetable:row-clicked="onRowClicked"
      >
        <div slot="user-detail-slot" slot-scope="props">
          {{ props.rowData.name }}
          <div class="media">
            <a class="avatar" :class="{ 'status-warning': props.rowData.privileges.indexOf('ROCKET-ARBITER') !=-1 }" href="#">
              <img :src="props.rowData.avatar" alt="...">
            </a>
            <div class="media-body">
              <p>
                <a class="hover-primary" href="#">
                  <strong>{{ props.rowData.name }}</strong>
                </a>
                <time class="float-right" :datetime="props.rowData.createdAt">{{ props.rowData.createdAt }}</time>
              </p>
              <p>
                <span
                  v-for="item in props.rowData.privileges"
                  :key="item"
                  class="badge badge-pill badge-default"
                  :class="{ 'text-warning': item=='ROCKET-ARBITER' }"
                >{{ item }}
                </span>
                {{ props.rowData.createdAt }}
              </p>
            </div>
          </div>
        </div>
      </vuetable>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vuetable from "vuetable-2";
import { mapState } from "vuex";

export default {
  components: {
    Vuetable,
  },
  data() {
    return {
      fieldsUsers: [
        {
          name: "user-detail-slot",
          title: ""
        }
      ]
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["API_ROOT"])
  },
  methods: {
    userDataTransform(data) {
      console.log(data);
      return data;
    },
    onRowClicked(row, ev) {
      console.log("User clicked", row);
      this.$emit("rowClicked", row, ev); // Ésto probablemente deberia ir directo arriba
    },
    updateList() {
      this.$refs.vuetableProfiles.reload();
    }
  }
};
</script>
