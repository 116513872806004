<style scoped>
.avatar {
  background-color: unset;
}
</style>

<template>
  <div class="row">
    <div class="col-md-12 col-lg-4">
      <ProfileBox
        :name="user.name"
        :email="user.email"
        :avatar="user.avatar"
        :privileges="user.privileges"
      />
      <Userlist
        v-if="privileges && privileges.indexOf('ROCKET-ARBITER') > -1"
        ref="profiles"
        @rowClicked="onRowClicked"
      />
    </div>
    <div class="col-md-12 col-lg-8">
      <UserEditBox
        v-if="editingUser.editedUserId"
        :fullname="editingUser.fullname"
        :email="editingUser.email"
        :avatar="editingUser.avatar"
        :privileges="editingUser.privileges"
        :edited-user-id="editingUser.editedUserId"
        @requestSave="onSaveRequest"
        @requestCancel="onCancelRequest"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import ProfileBox from "@/components/ProfileBox.vue";
import Userlist from "@/components/rocket/UserlistTable.vue";
import UserEditBox from "@/components/UserEditBox.vue";

import UsersApi from "@/services/Users.service";

export default {
  components: {
    ProfileBox,
    Userlist,
    UserEditBox
  },
  data() {
    return {
      editingUser: {
        editedUserId: "",
        fullname: "",
        email: "",
        avatar: "",
        privileges: []
      },
    };
  },
  computed: {
    // mapState hace que this.variable sea como llamar this.$store.variable (solo lectura)
    // Y ya no hace falta llamar a `commit`, se lee directo
    ...mapState(["privileges", "user"])
  },
  methods: {
    onRowClicked(rowClicked, ev) {
      console.log( "User Selected", rowClicked);
      const { data:row = {} } = rowClicked;
      this.editingUser.editedUserId  = row._id;
      this.editingUser.fullname  = row.name;
      this.editingUser.email = row.email;
      this.editingUser.avatar = row.avatar;
      this.editingUser.privileges = row.privileges.sort();
    },
    onSaveRequest(newPrivileges) {
      console.log( "Save is requested", this.editingUser.editedUserId, newPrivileges);
      UsersApi.updatePrivileges(this.editingUser.editedUserId, newPrivileges)
        .then(res => {
          this.$refs.profiles.updateList();
          console.log(res);
          this.$swal({
            position: "bottom-end",
            toast: true,
            icon: "success",
            title: "Privilegios actualizados",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        })
        .catch(e => {
          console.log("Privileges Save FAILED ", e);

          this.$swal({
          // position: "bottom-end",
            icon: "error",
            title: "No se pudo guardar privilegios",
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          });
        });
    },
    onCancelRequest() {
      this.editingUser = {
        editedUserId: "",
        fullname: "",
        avatar: "",
        privileges: []
      };
    }
  }
};
</script>
